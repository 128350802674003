<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                走进上交高金，探讨机器学习在金融领域的应用
              </p>
              <div class="fst-italic mb-2">2024年3月25日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/76/01-讲座.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    3月17日，非凸科技联合创始人兼CTO李佐凡受邀参加由上海交通大学上海高级金融学院MBA学联量投俱乐部组织的讲座活动，分享了机器学习在金融领域的应用，以及大模型在股票市场的迁移与影响。
                  </p>
                  <p>
                    活动上，李佐凡与同学们讨论了不同的投资风格，探讨了如何用更好的方法来帮助巴菲特进行价值投资。随后，举例常见的机器学习方法“CNN”，讲解了机器学习在数智交易中的具体应用，以及如何通过这些前沿技术来提高交易策略的性能。此外，李佐凡还分享了交易策略的验证方法，如何通过时间尺度转换和国际市场转换来测试模型的鲁棒性等。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/76/02-李佐凡.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    以德州扑克的博弈过程为例，李佐凡与同学们深入互动，阐释了交易的逻辑，探讨了在极端市场情况下策略模型的表现，以及数智交易对市场流动性和价格发现机制的影响。他表示，做投资策略时，不仅要对市场进行预测，也要对投资对手进行预测。
                  </p>
                  <p>
                    当前，大模型发展如火如荼，金融行业也紧密关注，如何利用这些模型处理财报数据、新闻、社交媒体信息等来提高投资决策的准确性就显得尤为重要。在问答环节，李佐凡就大模型在交易中的应用现状、发展趋势与同学们展开了激烈探讨，干货满满。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/76/03-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    此次线下分享，不仅为同学们提供了金融领域机器学习应用的多维视角，还加强了学术界与实践界之间的交流与合作。未来，非凸科技将继续携手高校，共同推进金融科技创新升级和深度应用，为金融行业数智化升级贡献更多力量。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News76",
};
</script>

<style></style>
